<template>
  <a-divider orientation="left" class="mt-4">Documentation</a-divider>

  <a-tabs
    v-model:activeKey="activeKey"
    :size="size"
    class="tabs-container mx-5"
  >
    <a-tab-pane key="english" tab="English">
      <a-row class="cards-box" :gutter="[16, 32]">
        <a-col
          v-for="item in portalConfig"
          :key="item.alt"
          :xs="24"
          :sm="24"
          :md="24"
          :lg="12"
          :xl="12"
          class="center"
        >
          <div
            v-if="item.type === 'tutorial'"
            class="item-box tutorial-box center"
          >
            <div class="video-box">
              <iframe
                width="100%"
                height="100%"
                :src="item.link"
                allowfullscreen
                webkitAllowFullScreen
                mozAllowfullscreen
              ></iframe>
            </div>
            <div class="content-box p-4 flex-fill">
              <h5 class="item-title">{{ item.title }}</h5>
              <p class="item-description">{{ item.message }}</p>
            </div>
          </div>
          <div v-else class="item-box doc-box center p-4">
            <h5 class="item-title">{{ item.title }}</h5>
            <p class="item-description">{{ item.message }}</p>

            <a-button type="primary" :href="item.link" class="download-button">
              <template #icon>
                <DownloadOutlined />
              </template>
              Download
            </a-button>
          </div>
        </a-col>
      </a-row>
    </a-tab-pane>

    <a-tab-pane key="español" tab="Español">
      <a-row class="cards-box" :gutter="[16, 32]">
        <a-col
          v-for="item in portalConfigEspañol"
          :key="item.alt"
          :xs="24"
          :sm="24"
          :md="24"
          :lg="12"
          :xl="12"
          class="center"
        >
          <div
            v-if="item.type === 'tutorial'"
            class="item-box tutorial-box center"
          >
            <div class="video-box">
              <iframe
                width="100%"
                height="100%"
                :src="item.link"
                allowfullscreen
                webkitAllowFullScreen
                mozAllowfullscreen
              ></iframe>
            </div>
            <div class="content-box p-4 flex-fill">
              <h5 class="item-title">{{ item.title }}</h5>
              <p class="item-description">{{ item.message }}</p>
            </div>
          </div>
          <div v-else class="item-box doc-box center p-4">
            <h5 class="item-title">{{ item.title }}</h5>
            <p class="item-description">{{ item.message }}</p>

            <a-button type="primary" :href="item.link" class="download-button">
              <template #icon>
                <DownloadOutlined />
              </template>
              Download
            </a-button>
          </div>
        </a-col>
      </a-row>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import { DownloadOutlined } from '@ant-design/icons-vue';
import { portalConfig, portalConfigEspañol } from './config';
import question from './questions.json';

export default {
  components: {
    DownloadOutlined,
  },
  data() {
    return {
      activeKey: 'english',
      list: question.list,
      portalConfig: portalConfig.filter((item) => !item.isOutDated),
      portalConfigEspañol: portalConfigEspañol.filter(
        (item) => !item.isOutDated
      ),
      source: '',
    };
  },
  methods: {
    handleChange() {
      this.source = '';
    },
    clickDownload(link) {
      window.open(link, '_blank');
    },
  },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
i {
  font-size: 40px;
}

p {
  font-family: 'Montserrat', sans-serif;
  margin: 1.5em 0;
}

a {
  text-decoration: none;
}

span {
  font-weight: 600;
}

.cards-box {
  max-width: 1200px;
  padding: 2em 3em;
  margin: 0 auto !important;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-box {
  flex-direction: column;
  height: 580px;
  max-width: 385px;
  background: #fff;
  text-align: center;
  box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 24px 12px;
}

.doc-box {
  width: 385px;
}

.video-box {
  width: 100%;
  height: 250px;
}
.tabs-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cards-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.item-box {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}

/* .item-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
} */

.video-box {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
}

.video-box iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.content-box {
  padding: 16px;
}

.item-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
}

.item-description {
  font-size: 1rem;
  color: #666;
}

.download-button {
  margin-top: 16px;
}

@media (max-width: 576px) {
  .item-box {
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 576px) {
  .cards-box {
    padding: 0;
  }
}
</style>
